
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TaxDocumentPage',

  components: {
    DocumentsList: defineAsyncComponent(() => import('@/components/account/Documents/DocumentsList.vue')),
    PDEMessageBox: defineAsyncComponent(() => import('@/components/pde/PDEMessageBox.vue')),
    PDESkeleton: defineAsyncComponent(() => import('@/components/pde/PDESkeleton.vue')),
    PDESvg: defineAsyncComponent(() => import('@/components/pde/PDESvg.vue')),
  },

  data: () => ({
    analytics1099: {
      download: {
        name: 'Download Click',
        text: '1099',
        area: 'documents/credentialing/1099',
      },
      open: {
        name: 'Preview Click',
        text: '1099',
        area: 'documents/credentialing/1099',
      },
      expand: {
        name: 'My Documents Expand Button Click',
        text: 'Expand',
        area: 'content-pane/financial-documents',
      },
    },
  }),

  computed: {
    ...mapGetters('documents', ['isLoading', 'taxes']),
  },
}
